import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "../utils/array-util"

export const NLPLinkMap = {
  //People
  Harari: "606ed14c7c49",
  "John Locke": "606ed14c7c49",
  "Hannah Arendt": "606ed14c7c49",
  "Isaac Asimov": "606ed14c7c49",

  //Concepts
  Akıl: "606ed14c7c49",
  "Cognitive Science": "606ed14c7c49",
  Zeka: "606ed14c7c49",
  "Üç Robot Yasası": "606ed14c7c49",

  "Reinforcement Learning": "eac85d27d438",
  "Makine Öğrenmesi": "eac85d27d438",
  "Linear Regressyon": "eac85d27d438",
  "Logit Regression": "eac85d27d438",
  "Decision Tree": "eac85d27d438",
  "Support Vector Machine": "eac85d27d438",
  "Naive Bayes": "eac85d27d438",
  "KNN (K- Nearest Neighbors)": "eac85d27d438",
  "Deep Learning": "eac85d27d438",

  "Yapay Zeka": "6e52e4a7c837",
  "Doğal Dil İşleme": "6e52e4a7c837",
  "Makine Öğrenmesi2": "6e52e4a7c837",

  "Structured Data": "90d4ea6309ca",
  "Semi-Structured Data": "90d4ea6309ca",
  "Unstructured Data": "90d4ea6309ca",
  "Metin Madenciliği": "90d4ea6309ca",
  "Semantic Annotation": "90d4ea6309ca",
  "Text Identification": "90d4ea6309ca",
  "Text Analysis": "90d4ea6309ca",
  "Concept Extraction": "90d4ea6309ca",
  "Relationship Extraction": "90d4ea6309ca",
  "Indexing Graph Database": "90d4ea6309ca",

  "TF-IDF": "53feb22a17c6",
  "Term Frequency": "53feb22a17c6",
  "Inverse Document Frequency": "53feb22a17c6",
  Lucene: "53feb22a17c6",

  Tokenization: "e11b87b28847",
  "Remove Unused Words/Chars": "e11b87b28847",
  "Normalization Words": "e11b87b28847",
  "String Distance": "e11b87b28847",
  Stemming: "e11b87b28847",
  "Sentence Segmentation": "e11b87b28847",
  "Bag Of Words": "e11b87b28847",
  NGram: "e11b87b28847",
  "Document Vector": "e11b87b28847",
  "Vector Space Model": "e11b87b28847",
  Corpus: "e11b87b28847",
  "TDM vs DTM": "e11b87b28847",
  "Feature Extraction": "e11b87b28847",

  "Reduce Dimension": "e11b87b28847",
  "Feature Reduction": "e11b87b28847",
  "Classification Models": "e11b87b28847",
  Clustering: "e11b87b28847",
  "Topic Modeling": "e11b87b28847",
  "Name Entity Recognation": "e11b87b28847",

  Accuracy: "4efe82fa3f21",
  "Sensitivity/Recall": "4efe82fa3f21",
  Specificity: "4efe82fa3f21",
  "Precision/Positive Predictive Value": "4efe82fa3f21",
  "Negative Prediction Value ": "4efe82fa3f21",

  "True Positive": "4efe82fa3f21",
  "True Negative": "4efe82fa3f21",
  "False Positive": "4efe82fa3f21",
  "False Negative": "4efe82fa3f21",

  "Word Embeddings": "943bf87dcc67",
  "One Hot Encoding": "943bf87dcc67",
  "Count Vector": "943bf87dcc67",
  "TF-IDF2": "943bf87dcc67",
  "Co-Occurrence Matrix": "943bf87dcc67",
  CBOW: "943bf87dcc67",
  "Skip-Gram": "943bf87dcc67",
  Word2Vec: "943bf87dcc67",
  Glove: "943bf87dcc67",

  "Naive Bayes2": "a9e5f5b48ab2",
  "Supervised Learning": "a9e5f5b48ab2",
  "Bag Of Words2": "a9e5f5b48ab2",
  Discriminitive: "a9e5f5b48ab2",
  Generative: "a9e5f5b48ab2",

  "Bag Of Words3": "32b1f0017a95",
  "Verileri Toplama": "32b1f0017a95",
  Vocabulary: "32b1f0017a95",
  "Doc Vector": "32b1f0017a95",
  Scoring: "32b1f0017a95",
  "Word Hashing": "32b1f0017a95",
  "Dense/Sparse Vector": "32b1f0017a95",
  CBOW2: "32b1f0017a95",
  "Skip-Gram2": "32b1f0017a95",

  NGrams: "74c30162c6da",
  Unigram: "74c30162c6da",
  Bigrams: "74c30162c6da",
  Trigrams: "74c30162c6da",

  "Vector Space Model2": "221befd9c8c8",
  "Sözlük Oluşturma": "221befd9c8c8",
  "Topic Vector": "221befd9c8c8",
  "Vector Normalization": "221befd9c8c8",
  "Cosine Similarity": "221befd9c8c8",

  Word2Vec3: "a314a37c45aa",
}

const storiesGroup = [
  {
    title: "Kullanım Alanları",
    storyPart: [
      {
        title: "Robotlar Akıllandı ve Felsefe",
        postId: "606ed14c7c49",
      },
      {
        title: "Makine Öğrenmesi",
        postId: "eac85d27d438",
      },
      {
        title: "Yapay Zeka, Makine Öğrenmesi ve NLP",
        postId: "6e52e4a7c837",
      },
      {
        title: "Makine Öğrenmesi ve Metin Madenciliği",
        postId: "90d4ea6309ca",
      },
    ],
  },

  {
    title: "Algoritma ve Yöntemler",
    storyPart: [
      {
        title: "TF-IDF",
        postId: "53feb22a17c6",
      },
      {
        title: "Metin Madenciliği Algoritmaları",
        postId: "e11b87b28847",
      },
      {
        title: "Sınıflandırma Modellerinin Performansı",
        postId: "4efe82fa3f21",
      },
      {
        title: "Word Embeddings",
        postId: "943bf87dcc67",
      },
      {
        title: "Naive Bayes Metin Sınıflandırması",
        postId: "a9e5f5b48ab2",
      },
      {
        title: "Bag Of Words",
        postId: "32b1f0017a95",
      },
      {
        title: "NGrams",
        postId: "74c30162c6da",
      },
      {
        title: "Vector Space Model",
        postId: "221befd9c8c8",
      },
      {
        title: "Word2Vec",
        postId: "a314a37c45aa",
      },
    ],
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: NLPLinkMap,
  title: "Algoritma - Doğal Dil İşleme",
  path: "dogal-dil-isleme",
}

const NLPPage = () => (
  <StoryGroup
    title={context.title}
    complex={true}
    stories={storiesGroup}
    linkMap={context.linkMap}
  />
)
export default NLPPage
